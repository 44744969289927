import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { Mentee } from '../../../shared/types/mentee.types';
import { UpdateMentee } from 'src/app/state/actions/mentee.actions';
import { Mentor } from '../../../shared/types/mentor.types';
import { AppState } from 'src/app/state/reducers';
import { INNERSMARTGOALSGENERATOR } from 'src/app/app-routing.module';
import { Store } from '@ngrx/store';
import { Editor } from 'primeng/editor';
import { ConversationService } from '../../../shared/services/conversation.service';
import { MenteeRelation } from '../../../shared/types/mentee-relations.types';
import { EmailLogService } from 'src/app/shared/services/email-log.service';

@Component({
  selector: 'fp-goals-card',
  templateUrl: './goals-card.component.html',
  styleUrl: './goals-card.component.scss',
})
export class GoalsCardComponent implements OnChanges {
  constructor(
    private conversationService: ConversationService,
    private emailLogService: EmailLogService,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.editorGoalsTarget?.nativeElement.contains(e.target)) {
        this.divGoals?.nativeElement.classList.remove('fix-height-list', 'mt-4');
      } else if (!this.editorGoalsTarget?.nativeElement.contains(e.target)) {
        this.divGoals?.nativeElement.classList.add('fix-height-list', 'mt-4');
      }
      if (this.goalsForm.dirty) {
        this.goalsChange(this.goalsForm);
      }
    });
  }
  @Input() role: Mentee | Mentor;
  @Input() relation: MenteeRelation;
  @ViewChild('divGoals') divGoals?: ElementRef;
  @ViewChild('editorGoals', { read: ElementRef }) editorGoalsTarget: ElementRef;
  @ViewChild('shareGoalsBtn') shareGoalsBtn;
  INNERSMARTGOALSGENERATOR = INNERSMARTGOALSGENERATOR;
  faWand = faWandMagicSparkles;
  goalsForm = this.formBuilder.group({
    goals: [null, []],
  });
  goalsWhenFinished: string;

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes['role'] &&
      (changes['role']['previousValue'] === null || changes['role']['previousValue'] === undefined) &&
      this.role?.mentee_goals
    ) {
      this.goalsForm.setValue({ goals: this.role.mentee_goals });
      this.goalsWhenFinished = this.role.mentee_goals;
    }
  }

  goalsChange(form: UntypedFormGroup) {
    if (form.valid && form.dirty) {
      const goals: Partial<Mentee> = { mentee_goals: form.value.goals };
      this.store.dispatch(new UpdateMentee(this.role.id, goals));
      form.markAsPristine();
    }
  }

  shareGoals() {
    if (this.relation.supporter.user_account) {
      // create conversation with notification id=16 == edited goals
      this.conversationService
        .createConversation(this.relation.mentee.submission_id, this.relation.supporter, undefined, 16, this.relation.id)
        .subscribe({
          next: () => this.goalsSharedGraphics(),
        });
    } else {
      // create an email and send it to supporter without user account
      this.emailLogService.newEmailLogs(this.relation.id, [105], this.relation.supporter.type).subscribe({
        next: () => this.goalsSharedGraphics(),
      });
    }
  }

  goalsSharedGraphics() {
    this.shareGoalsBtn.nativeElement.innerText = 'Mentor notified';
    this.shareGoalsBtn.nativeElement.disabled = true;
    this.shareGoalsBtn.nativeElement.classList.remove('p-button-success');
    this.shareGoalsBtn.nativeElement.classList.add('p-button-secondary');
  }
}
