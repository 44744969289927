<div *ngIf="kickoffYTUrl" class="container-iframe large-video">
  <iframe
    [src]="kickoffYTUrl"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    class="responsive-iframe large-video"
  >
  </iframe>
</div>
<div *ngIf="kickoffExternalLink" class="flex">
  <a class="small-video flex flex-column justify-content-center align-items-center py-3" target="_blank" [href]="kickoffExternalLink">
    <fa-icon [icon]="faVideo"></fa-icon>
    <p-button label="Watch it" styleClass="p-button-warning text-14 w-7rem"></p-button>
  </a>
</div>
