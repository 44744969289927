import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-recording',
  templateUrl: './recording.component.html',
  styleUrl: './recording.component.scss',
})
export class RecordingComponent {
  @Input() kickoffYTUrl: SafeResourceUrl;
  @Input() kickoffExternalLink: SafeResourceUrl;
  faVideo = faVideo;
}
