<div class="card flex flex-column lg:flex-row p-1 border-1 border-transparent">
  <div class="align-self-center px-3 py-1 font-bold">Your goals for mentoring</div>
  <ng-container *ngIf="[10, 11, 6].includes(relation.mr_status)">
    <form [formGroup]="goalsForm" class="relative text-sm m-0 w-full h-full" (keyup)="goalsChange(goalsForm)">
      <div #divGoals class="fix-height-list mt-4">
        <p-editor
          #editorGoals
          formControlName="goals"
          [placeholder]="
            relation?.supporter.type === 'mentor' ? 'Start typing..' : 'Add your goals after you define the SMART goals with your coach.'
          "
          styleClass="border-none"
          class="p-editor-hide-toolbar"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <button type="button" class="ql-list" value="ordered" aria-label="Ordered list"></button>
              <button type="button" class="ql-list" value="bullet" aria-label="Bullet list"></button>
            </span>
          </ng-template>
        </p-editor>

        <ng-container *ngIf="relation?.supporter.type === 'mentor'">
          <div class="absolute right-0 top-0 hidden">
            <button
              #shareGoalsBtn
              *ngIf="relation?.mr_status === 10"
              pButton
              type="button"
              label="Notify"
              [disabled]="!goalsForm.value.goals"
              (click)="shareGoals()"
              class="p-button-success text-xs py-1"
              [pTooltip]="
                !this.shareGoalsBtn.disabled
                  ? 'Notify your mentor when you changed your goals. They will be notified in the platform.'
                  : null
              "
              tooltipPosition="bottom"
              data-umami-event="Notify edited goals"
            ></button>
            <button
              pButton
              type="button"
              [routerLink]="'../' + INNERSMARTGOALSGENERATOR"
              [disabled]="!goalsForm.value.goals"
              class="p-button-redirect text-xs p-1 ml-1"
              pTooltip="Generate your SMART goals"
              tooltipPosition="bottom"
              data-umami-event="Use smart goals generator"
            >
              <fa-icon #icon [icon]="faWand"></fa-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="![10, 11, 6].includes(relation.mr_status)">
    <div class="fix-height-list">
      <p-editor [(ngModel)]="goalsWhenFinished" styleClass="border-none" class="p-editor-hide-toolbar" [readonly]="true"></p-editor>
    </div>
  </ng-container>
</div>
