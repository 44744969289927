<p-dialog
  breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '60vw' }"
  [(visible)]="display"
  dismissableMask="true"
  modal="true"
>
  <ng-container *ngIf="!loading; else loadingCircle">
    <div class="m-4 text-center">
      <h2 class="pr-3">Image editor</h2>
      <div class="field mb-4">
        <input type="file" class="hidden" (change)="fileChangeEvent($event)" pInputText #photoUpload />
        <button pButton icon="pi pi-paperclip" class="p-button-outlined w-auto" (click)="photoUpload.click()" label="Choose photo"></button>
        <div class="h-full inline-block pl-3 pb-1">{{ fileName }}</div>
      </div>
      <image-cropper
        #imgCropper
        [imageChangedEvent]="imageChangedEvent"
        [imageBase64]="imageBase64"
        [aspectRatio]="1 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        output="base64"
        [style.display]="showCropper ? null : 'none'"
        [roundCropper]="true"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
      ></image-cropper>
      <div *ngIf="croppedImage" class="flex flex-row flex-wrap justify-content-around gap-3 mt-7 mb-5">
        <button
          pButton
          pRipple
          (click)="rotateLeft()"
          icon="pi pi-replay"
          class="p-button-rounded p-button-info"
          pTooltip="Rotate left"
        ></button>
        <button
          pButton
          pRipple
          (click)="rotateRight()"
          icon="pi pi-refresh"
          class="p-button-rounded p-button-info"
          pTooltip="Rotate right"
        ></button>
        <button
          pButton
          pRipple
          (click)="flipHorizontal()"
          icon="pi pi-arrow-right-arrow-left"
          class="p-button-rounded p-button-info"
          pTooltip="Flip horizontal"
        ></button>
        <button
          pButton
          pRipple
          (click)="flipVertical()"
          icon="pi pi-sort-alt"
          class="p-button-rounded p-button-info"
          pTooltip="Flip vertical"
        ></button>
        <button
          pButton
          pRipple
          (click)="zoomOut()"
          icon="pi pi-search-minus"
          class="p-button-rounded p-button-info"
          pTooltip="Zoom -"
        ></button>
        <button
          pButton
          pRipple
          (click)="zoomIn()"
          icon="pi pi-search-plus"
          class="p-button-rounded p-button-info"
          pTooltip="Zoom +"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-eraser"
          class="p-button-rounded p-button-warning"
          pTooltip="Reset image"
          (click)="resetImage()"
        ></button>
      </div>
      <p-button *ngIf="croppedImage" (click)="uploadCroppedImage()" class="upload-btn" styleClass="p-button-warning">
        <i class="pi pi-spinner pi-spin" *ngIf="loadingBtn"> </i> &nbsp; {{ loadingBtn ? 'Saving' : 'Save Image' }} &nbsp;</p-button
      >
      <p-button *ngIf="croppedImage" (click)="removeImage()" class="upload-btn block mt-3" [outlined]="true" severity="danger">
        <i class="pi pi-spinner pi-spin" *ngIf="loadingRemoveBtn"> </i> &nbsp;
        {{ loadingRemoveBtn ? 'Deleting' : 'Delete Image' }} &nbsp;</p-button
      >
    </div>
  </ng-container>
  <ng-template #loadingCircle class="flex justify-content-center">
    <div class="flex justify-content-center"><p-progressSpinner animationDuration="1s"></p-progressSpinner></div
  ></ng-template>
</p-dialog>

<p-dialog
  header="Badge preview"
  [(visible)]="displayPreview"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '650px' }"
  [draggable]="false"
  [resizable]="false"
  modal="true"
>
  <ng-container [ngTemplateOutlet]="loadingCircle" *ngIf="!badgePreviewLoaded"></ng-container>
  <img
    #badgePreviewElement
    *ngIf="displayPreview"
    [src]="badgeSrc"
    alt="Badge Preview"
    [hidden]="!badgePreviewLoaded"
    (load)="previewBadgeLoaded()"
  />
</p-dialog>

<div class="flex flex-row flex-wrap align-items-center justify-content-center container-border">
  <div class="flex" *ngIf="model?.photo">
    <div class="photo-preview relative" *ngIf="photoPreview">
      <img [src]="photoPreview" alt="Image" class="border-circle" />
      <ng-container *ngIf="model?.photo">
        <button
          pButton
          pTooltip="Edit photo"
          type="button"
          icon="pi pi-pencil"
          class="edit-btn p-button-rounded p-button-secondary"
          [disabled]="!modelReady"
          (click)="showDialog()"
        ></button>
      </ng-container>
    </div>
    <div class="photo-preview flex justify-content-center align-items-center" *ngIf="!photoPreview">
      <p-progressSpinner animationDuration="1s"></p-progressSpinner>
    </div>
  </div>
  <div *ngIf="!model?.photo">
    <button
      pTooltip="No photo uploaded"
      tooltipPosition="bottom"
      pButton
      type="button"
      class="circle p-button-rounded p-button-outlined p-button-warning"
      [disabled]="!modelReady"
      (click)="showDialog()"
    >
      +
    </button>
  </div>

  <div class="w-full mt-2 text-center">
    <button
      *ngIf="type === 'mentor'"
      [disabled]="!model?.badge"
      (click)="getBadge(model.badge)"
      icon="pi pi-external-link"
      class="mt-3 p-button-outlined p-button-warning w-7 xl:w-full"
      pButton
      pRipple
      label="Badge"
    ></button>
    <button
      *ngIf="type === 'coach' && model?.photo"
      (click)="showDialog()"
      class="mt-3 p-button-warning max-w-12rem"
      pButton
      pRipple
      label="Edit photo"
    ></button>
    <button
      *ngIf="type === 'coach' && !model?.photo"
      (click)="showDialog()"
      class="mt-3 p-button-warning max-w-12rem"
      pButton
      pRipple
      label="Upload photo"
    ></button>
    <div class="flex flex-wrap gap-3 mt-4 text-center justify-content-center">
      <button
        *ngIf="type === 'mentee'"
        [disabled]="!model?.badge_join"
        (click)="getBadge(model.badge_join)"
        class="px-5 p-button-warning w-12rem mentee-badge"
        pButton
        pRipple
        label="Mentee badge"
      ></button>
      <button
        *ngIf="type === 'mentee'"
        [disabled]="!model?.badge_alumni"
        (click)="getBadge(model.badge_alumni)"
        class="px-5 p-button-warning w-12rem mentee-badge"
        pButton
        pRipple
        label="Alumni badge"
      ></button>
    </div>
  </div>
</div>
